import { useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';

import Button from 'components/Button/Button';
import ContentMedia from 'components/ContentMedia/ContentMedia';
import RichText from 'components/RichText/RichText';
import VideoBlock from 'components/VideoBlock/VideoBlock';
import {
  MediaCMS,
  MediaType,
  VideoTypesCMS,
} from 'utils/sharedStrapiQueries/sharedTypes';

import { carouselTextAnimations } from '../VisualCarousel.animations';

import * as S from './IntroSlide.styles';

export interface IntroSlideProps {
  media: MediaCMS;
  mediaType: MediaType;
  videoErrorMessage: string;
  videoType: VideoTypesCMS;
  setIsIntroSlides: (value: boolean) => void;
  introSupportingText: string;
  introSupportingButton: { label: string; href: string };
  secondIntroSupportingText: string;
  secondIntroSupportingButton: { label: string; href: string };
  headline: string;
}

const IntroSlide: React.FunctionComponent<IntroSlideProps> = ({
  media,
  mediaType,
  videoErrorMessage,
  videoType,
  setIsIntroSlides,
  introSupportingText,
  introSupportingButton,
  secondIntroSupportingText,
  secondIntroSupportingButton,
  headline,
  ...rest
}) => {
  const animate = useAnimation();
  const second_animate = useAnimation();

  useEffect(() => {
    const startAnimation = async () => {
      await animate.start(carouselTextAnimations.enter);
      await new Promise(resolve => setTimeout(resolve, 5000));
      await animate.start(carouselTextAnimations.exit);

      await new Promise(resolve => setTimeout(resolve, 2000));

      await second_animate.start(carouselTextAnimations.enter);
      await new Promise(resolve => setTimeout(resolve, 5000));
      await second_animate.start(carouselTextAnimations.exit);

      setIsIntroSlides(false);
    };

    startAnimation();
  }, []);

  return (
    <>
      <S.ImageWrapper>
        {videoType?.source === 'azure' || videoType?.source === 'vimeo' ? (
          <VideoBlock
            videoSource={videoType?.source}
            videoSourceId={videoType?.sourceId}
            azureUserId={videoType?.azureUserId}
            autoplay={videoType?.autoplay}
            manualPlay={videoType?.manualPlay}
            mute={videoType?.mute}
            withSound={videoType?.withSound}
            azurePlaceholder={videoType?.azurePlaceholder}
            vimeoBackground={videoType?.vimeoBackground}
            disableInView
          />
        ) : (
          <ContentMedia
            media={media}
            mediaType={mediaType}
            videoErrorMessage={videoErrorMessage}
            autoplay={videoType?.autoplay}
            mute={videoType?.mute}
          />
        )}
      </S.ImageWrapper>

      <S.IntroSlideWrapper {...rest}>
        <S.MiddleTextWrapper>
          <S.Container>
            <RichText
              as="h1"
              size="heading1"
              richText={headline}
              defaultFormatting
            />
          </S.Container>
        </S.MiddleTextWrapper>

        <S.BottomTextWrapper
          animate={animate}
          initial={{ opacity: 0, display: 'none' }}
        >
          {introSupportingText && (
            <RichText
              as="h3"
              size="heading4"
              richText={introSupportingText}
              defaultFormatting
            />
          )}
          {introSupportingButton?.label && (
            <Button
              label={introSupportingButton?.label}
              href={introSupportingButton?.href}
              transparent
            />
          )}
        </S.BottomTextWrapper>

        <S.BottomTextWrapper
          animate={second_animate}
          initial={{ opacity: 0, display: 'none' }}
        >
          {secondIntroSupportingText && (
            <RichText
              as="h3"
              size="heading4"
              richText={secondIntroSupportingText}
              defaultFormatting
            />
          )}
          {secondIntroSupportingButton?.label && (
            <Button
              label={secondIntroSupportingButton?.label}
              href={secondIntroSupportingButton?.href}
              transparent
            />
          )}
        </S.BottomTextWrapper>
      </S.IntroSlideWrapper>
    </>
  );
};

export default IntroSlide;
