import { wrap } from '@motionone/utils';
import { AnimatePresence } from 'framer-motion';
import sampleSize from 'lodash/sampleSize';
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import useOverElement from 'hooks/useOverElement';
import { useCopyStore } from 'store';
import { ReactComponent as SvgLeftArrow } from 'svgs/carousel_nav_arrow_left.svg';
import { ReactComponent as SvgRightArrow } from 'svgs/carousel_nav_arrow_right.svg';
import { Timer } from 'utils';
import {
  MediaCMS,
  MediaType,
  VideoTypesCMS,
} from 'utils/sharedStrapiQueries/sharedTypes';

import IntroSlide, { IntroSlideProps } from './IntroSlide/IntroSlide';
import { carouselAnimations } from './VisualCarousel.animations';
import VisualCarouselSlide from './VisualCarouselSlide/VisualCarouselSlide';

import * as S from './VisualCarousel.styles';

export interface VisualCarouselSlidesProps extends VideoTypesCMS {
  media: MediaCMS;
  mediaType: MediaType;
  question?: string;
  clientName: string;
  caseStudy: { slug: string };
  buttonText: string;
  videoErrorMessage?: string;
}

export interface VisualCarouselProps {
  introSupportingText: IntroSlideProps['introSupportingText'];
  introSupportingButton: IntroSlideProps['introSupportingButton'];
  secondIntroSupportingText: IntroSlideProps['secondIntroSupportingText'];
  secondIntroSupportingButton: IntroSlideProps['introSupportingButton'];
  headline: IntroSlideProps['headline'];
  introContent: {
    media?: MediaCMS;
    mediaType: MediaType;
    videoErrorMessage?: string;
  } & VideoTypesCMS;
  slides: VisualCarouselSlidesProps[];
  duration: number;
}

const defaultProps: Partial<VisualCarouselProps> = {
  introSupportingText: '',
  introSupportingButton: { label: '', href: '/' },
  secondIntroSupportingText: '',
  secondIntroSupportingButton: { label: '', href: '/' },
  headline: '',
  introContent: {
    media: {
      url: '',
    },
    mediaType: 'image',
    source: 'self_hosted',
    autoplay: true,
    manualPlay: false,
    mute: false,
    withSound: false,
  },
  slides: [
    {
      media: {
        url: '',
      },
      mediaType: 'image',
      question: '',
      clientName: '',
      caseStudy: { slug: '' },
      buttonText: '',
      source: 'self_hosted',
      autoplay: true,
      manualPlay: false,
      mute: false,
      withSound: false,
    },
  ],
  duration: 2,
};

const VisualCarousel: FunctionComponent<VisualCarouselProps> = ({
  introSupportingText,
  introSupportingButton,
  secondIntroSupportingText,
  secondIntroSupportingButton,
  headline,
  introContent,
  slides,
  duration,
  ...rest
}) => {
  const { globalSettings } = useCopyStore(({ copy }) => copy);

  const timer = useRef(null);
  const interval = useRef(null);
  const randomSlides = useMemo(() => sampleSize(slides, 5), [slides]);

  const [isIntroSlides, setIsIntroSlides] = useState<boolean>(true);
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const [ref] = useOverElement();

  const hoverFillColor =
    globalSettings?.buttonsHoverColor ||
    globalSettings?.buttonsHoverDefaultColors;

  const setProperSlide = value =>
    setCurrentSlide(wrap(0, randomSlides.length, value));

  const resetSlider = () => {
    clearInterval(interval.current);

    timer.current = new Timer(() => {
      setProperSlide(currentSlide + 1);
    }, duration * 1000);
  };

  useEffect(() => {
    if (!isIntroSlides) resetSlider();
  }, [isIntroSlides]);

  useEffect(() => {
    const interv = setInterval(resetSlider, duration * 1000);

    return () => clearInterval(interv);
  }, [currentSlide]);

  const carouselSlides = randomSlides.map((props, index) => (
    <S.Motion
      key={index + `${currentSlide}`}
      variants={carouselAnimations}
      animate="enter"
      initial="initial"
      exit="exit"
    >
      <VisualCarouselSlide {...props} />
    </S.Motion>
  ));

  const introSlide = (
    <S.Motion
      variants={carouselAnimations}
      animate="enter"
      initial="initial"
      exit="exit"
    >
      <IntroSlide
        media={introContent?.media}
        mediaType={introContent?.mediaType}
        videoErrorMessage={introContent?.videoErrorMessage}
        videoType={{ ...introContent }}
        setIsIntroSlides={setIsIntroSlides}
        introSupportingText={introSupportingText}
        introSupportingButton={introSupportingButton}
        secondIntroSupportingText={secondIntroSupportingText}
        secondIntroSupportingButton={secondIntroSupportingButton}
        headline={headline}
      />
    </S.Motion>
  );

  return (
    <S.Wrapper {...rest}>
      <S.Slides ref={ref}>
        <AnimatePresence mode="popLayout">
          {isIntroSlides ? introSlide : carouselSlides[currentSlide]}
        </AnimatePresence>

        <S.LeftArrow hoverFillColor={hoverFillColor}>
          {isIntroSlides ? (
            <span />
          ) : (
            <SvgLeftArrow onClick={() => setProperSlide(currentSlide - 1)} />
          )}
        </S.LeftArrow>
        <S.RightArrow hoverFillColor={hoverFillColor}>
          <SvgRightArrow
            onClick={() => {
              isIntroSlides
                ? setIsIntroSlides(false)
                : setProperSlide(currentSlide + 1);
            }}
          />
        </S.RightArrow>
      </S.Slides>
    </S.Wrapper>
  );
};

VisualCarousel.defaultProps = defaultProps;

export default VisualCarousel;
