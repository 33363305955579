import { motion } from 'framer-motion';
import styled from 'styled-components';

import { MediaWrapper } from 'components/VideoBlock/VideoBlock.styles';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

const fullColumns = layout.columns.max;

export const IntroSlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;

  button {
    background-color: ${colors.codGray}1A;
  }
`;

export const ImageWrapper = styled.div`
  height: 100%;

  ${MediaWrapper} {
    height: 100%;
  }
`;

export const MiddleTextWrapper = styled.div`
  position: absolute;
  inset: 0;

  display: grid;
  grid-template-columns: [out-left] 16px [content] auto [out-right] 16px;
  place-items: center start;

  ${mediaTablet(`
    grid-template-columns: [out-left] 39px repeat(
      ${fullColumns},
      [content] minmax(0px, 1fr)
    ) [out-right] 39px;
  `)}
`;

export const Container = styled.div`
  grid-column: content 1 / out-right;

  ${mediaDesktop(`
    grid-column: content 1 / content 9;
    padding: 0 0 0 88px;
  `)};
`;

export const BottomTextWrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;

  padding: 0 16px 56px;

  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 32px;
  }

  ${mediaTablet(`
    flex-direction: row;

    padding: 0 39px 56px 127px;

    h3 {
      margin: 0 32px 0 0;
    }
  `)}
`;
